<template>
  <div>
    <div class="row-3">
      <div class="row-3-col-1" id="listVideo">
        <div class="card video-list">
          <div class="body">
            <div class="list-checkbox">
              <div
                v-for="item in avgs"
                :key="item.id"
                class="list-item"
                :id="item.id"
                @click="selectedVideo(item.id)"
                :class="{ 'active selected': selectedSeriesId == item.id }"
              >
                <i
                  class="icon icon-checkbox"
                  :class="{ checked: shownReportIds.find((e) => e == item.id) }"
                  @click.stop="shownAvg(item)"
                ></i>
                <div class="item-name pl-2 pr-2">平均値（{{ item.name }}）</div>
              </div>
              <div
                class="list-item item-compare"
                :class="{
                  'active selected': selectedSeriesId == 'video-' + video.id,
                }"
                v-for="(video, index) in videos"
                :key="'video-' + index"
                :id="'video-' + video.id"
                @click="selectedVideo(video)"
              >
                <div class="item">
                  <i
                    @click.stop="checkedVideo(video)"
                    class="icon icon-checkbox"
                    :class="{ checked: video.checked }"
                  ></i>
                  <div class="item-name pl-2 pr-2">
                    <span class="item-time">
                      {{ video.start_at | moment("YYYY/M/DD") }}
                      {{ video.video_length }}s</span
                    >
                    {{ video.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="settingAvg" @click="showSettingModal = true">
              <i class="icon icon-small-setting mr-1"></i>
              <div>平均値設定</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-3-col-2 chart-area" id="chartArea">
        <div
          v-if="interactionButtonColor.rank_2 && interactionButtonColor.rank_3"
        >
          <PolarRadar
            :seriesData="seriesData"
            :selectedId="selectedSeriesId"
            :videoDetail="videoDetail"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            @setChart="getChart"
            :nameChart="'Percentage'"
          ></PolarRadar>
        </div>
        <div>
          <div
            class="btn btn-default"
            id="showListVideo"
            @click="showListVideo"
            style="display: none"
          >
            リスト表示
          </div>
        </div>
      </div>
    </div>
    <setting-average
      v-show="showSettingModal"
      :buyerId="buyerId"
      :videos="videos"
      @closeModal="showSettingModal = false"
      @setSeriesData="setSeriesData"
      :step="1"
    ></setting-average>
  </div>
</template>
<script>
import {
  videoTimeRange,
} from "@/commons/constants";
import SettingAverage from "@/components/Top/Step-1/SettingAverage";
import PolarRadar from "@/components/Charts/PolarRadar.vue";
import {stepOptionTopPage} from "@/commons/charts";
import EventBus from "@/commons/event_bus";

import {
  formatSeriesDataSummaryAvgStep1,
  formatSeriesDataEachReactionAvg,
  formatSeriesDataSummaryVideoStep1,
} from "@/commons/charts";

const jQuery = require("jquery");

let hidenList = function () {
  jQuery("#listVideo").hide();
  jQuery("#showListVideo").show();
  jQuery(".video-report-area").height(400);
  jQuery("#chartArea").removeClass("row-3-col-2").addClass("row-3-col-3");
};
let showList = function () {
  jQuery("#listVideo").show();
  jQuery("#showListVideo").hide();
  jQuery(".video-report-area").height(353);
  jQuery("#chartArea").removeClass("row-3-col-3").addClass("row-3-col-2");
};

export default {
  components: {
    SettingAverage,
    PolarRadar,
  },

  props: {
    videos: Array,
    videoDetail: Object,
    dataReport: Object,
    adId: Number,
    buyerId: Number,
    summaryAvg: Object,
    checkedPrj: Boolean,
    project: Object,
  },
  data() {
    return {
      chart: null,
      checked: false,
      clients: [],
      stepOptions: stepOptionTopPage,
      changeStepOption: "changeStep",
      selectedSeriesId: "avg",
      shownReportIds: ["avg"],
      step: 1,
      part: 1,
      seriesData: [],
      imageURL: "",
      typeChart: true,
      column: 8,
      maxColumn: 8.5,
      lineChart: "chartVideo",
      videosMaxLengthSec: "",
      showSettingModal: false,
      avgs: [
        {
          checked: true,
          id: "avg",
          name: "すべての動画",
          value: 0,
        },
      ],
      summaryAvgData: {},
      projectSettingSelected: [],
      videoTimeRangesUnCheck: [],
    };
  },
  watch: {
    dataReport: {
      handler: function () {
        EventBus.$emit("reloadSettingAverage");
        this.avgs = [];
        this.avgs.push(videoTimeRange[0]);
        this.part = 1;
      },
      deep: true,
    },
    buyerId: {
      handler: function () {
        EventBus.$emit("reloadSettingAverage");
      },
    },
    summaryAvg: {
      handler: function () {
        this.seriesData = [formatSeriesDataSummaryAvgStep1(this.summaryAvg)];
        this.summaryAvgData = this.summaryAvg;
      },
    },
    videos: function (e) {
      this.videosMaxLength();
      this.defaultStepOption();
      let videoIds = e.map((item) => item.id);
      if (videoIds.length > 0) {
        if (this.checkedPrj) {
          this.selectedSeriesId = "avg";
          this.shownReportIds = ["avg"];
          this.seriesData = [
            formatSeriesDataSummaryAvgStep1(this.summaryAvg),
          ];
          this.$store.commit("updateVideoList", this.adId);
          for (let video of this.videos) {
            this.checkedVideo(video, true);
          }
        }
      } else {
        this.selectedSeriesId = "avg";
        this.shownReportIds = ["avg"];
        this.seriesData = [formatSeriesDataSummaryAvgStep1(this.summaryAvg)];
      }
      this.$emit("loadVideosImages");
    },
    summaryAvgData: {
      handler: function (data) {
        for (let time of videoTimeRange) {
          this.changeSeriesData(time.id, data);
        }
      },
    },
  },
  methods: {
    changeSeriesData(id, data) {
      let index = this.seriesData.findIndex((item) => item.id == id);
      if (index != -1) {
        let serieData = formatSeriesDataSummaryAvgStep1(data, id);
        this.seriesData.splice(index, 1, serieData);
      }
    },
    setSeriesData(data) {
      let videoTimeRanges = data.videoTimeRange;
      let projectIds = data.videoIds;
      this.avgs = [];
      this.summaryAvgData = data.summaryAvg;
      this.projectSettingSelected = projectIds;
      this.videoTimeRangesUnCheck = [];
      videoTimeRanges.forEach((item) => {
        this.avgs.push(
          videoTimeRange.find((range) => range.value == item.value)
        );
      });
      videoTimeRange.forEach((item) => {
        this.findVideoTimeRangeUnCheck(item);
      });
      this.videoTimeRangesUnCheck.forEach((item) => {
        this.findSeriaData(item);
        this.findShowReportIds(item);
      });

      if (videoTimeRange.find(item => item.id == this.selectedSeriesId)) {
        if (!this.avgs.find(item => item.id == this.selectedSeriesId)) {
          this.selectedChart(this.videos[0].id)
        }
      }
    },
    checkingVideoTimeRange(item) {
      let result = this.shownReportIds.find((id) => id == item.id);
      if (!result) {
        this.$set(item, "checked", false);
      } else {
        this.$set(item, "checked", true);
      }
    },
    findVideoTimeRangeUnCheck(time) {
      let index = this.avgs.findIndex((item) => item.id == time.id);
      if (index == -1) {
        this.videoTimeRangesUnCheck.push(time);
      }
    },
    findSeriaData(time) {
      let index = this.seriesData.findIndex((item) => item.id == time.id);
      if (index != -1) {
        this.seriesData.splice(index, 1);
      }
    },
    findShowReportIds(time) {
      let index = this.shownReportIds.findIndex((item) => item == time.id);
      if (index != -1) {
        this.shownReportIds.splice(index, 1);
      }
    },
    selectedChart(id) {
      let LIST_AVG_ID = ["avg", "avg-0-15", "avg-16-30", "avg-31-60", "avg-61"];
      let video;
      if (LIST_AVG_ID.includes(id)) video = id;
      else video = this.videos.find((e) => e.id == id);
      this.selectedVideo(video, false);
    },
    getChart(chart) {
      this.chart = chart;
    },
    hideListVideo() {
      hidenList();
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.reflow();
        }
      });
    },
    showListVideo() {
      showList();
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.reflow();
        }
      });
    },
    selectedVideo(video, reload = true) {
      let selectedSeriesId;
      if (
        video == "avg" ||
        video == "avg-0-15" ||
        video == "avg-16-30" ||
        video == "avg-31-60" ||
        video == "avg-61"
      ) {
        selectedSeriesId = video;
      } else {
        selectedSeriesId = "video-" + video.id;
      }

      if (this.shownReportIds.find((e) => e == selectedSeriesId)) {
        this.selectedSeriesId = selectedSeriesId;
        this.imageURL = video == "avg" ? "" : video.imageURL;
        if (reload) {
          this.$nextTick(() => {
            this.part = 1;
          });
        }
      }
    },
    shownAvg(item) {
      let id = item.id;
      let index = this.shownReportIds.findIndex((e) => e == id);
      if (index == -1) {
        this.shownReportIds.push(id);
        let serieData = formatSeriesDataSummaryAvgStep1(
          this.summaryAvgData,
          id
        );
        this.seriesData.push(serieData);
      } else {
        let index2 = this.seriesData.findIndex((e) => e.id == id);
        if (this.selectedSeriesId == id) {
          this.selectedSeriesId = null;
        }
        this.shownReportIds.splice(index, 1);
        this.seriesData.splice(index2, 1);
      }
    },
    checkedVideo(video, isInitData = false) {
      let index = this.shownReportIds.findIndex(
        (e) => e == "video-" + video.id
      );
      if (isInitData && this.$route.name === "top-compare") {
        this.$set(video, "checked", true);
      } else {
        this.$set(video, "checked", !video.checked);
      }
      this.$emit("selectVideo", video);
      if (index == -1 && video.checked) {
        this.shownReportIds.push("video-" + video.id);
        let dataReport = this.$store.getters.getReportSummary;
        let videoReport = dataReport
          ? dataReport.report_data.find((e) => e.video_id == video.id)
          : {};
        let seriesData = formatSeriesDataSummaryVideoStep1(videoReport, video);
        this.seriesData.push(seriesData);
      } else {
        let index2 = this.seriesData.findIndex(
          (e) => e.id == "video-" + video.id
        );
        if (this.selectedSeriesId == "video-" + video.id) {
          this.selectedSeriesId = null;
        }
        if (index2 != -1) {
          this.shownReportIds.splice(index, 1);
          this.seriesData.splice(index2, 1);
        }
      }
    },
    getSeriesDataChartEachInteraction() {
      let series = [];
      this.shownReportIds.forEach((id) => {
        if (
          id == "avg" ||
          id == "avg-0-15" ||
          id == "avg-16-30" ||
          id == "avg-31-60" ||
          id == "avg-61"
        ) {
          series.push(formatSeriesDataEachReactionAvg(this.summaryAvgData, id));
        } else {
          id = id.split("-")[1];
          let videoReportData = this.dataReport.report_data.find(
            (e) => e.video_id == id
          );
          if (videoReportData) {
            let serie = {
              id: "video-" + id,
              data: videoReportData.tally_result.map((e) => [
                e.second,
                e.left,
                Math.abs(e.right),
              ]),
            };
            series.push(serie);
          }
        }
      });
      return series;
    },
    handleChangeStepChart(step) {
      this.step = step;
      this.part = 1;
    },
    nextPart() {
      this.part += 1;
    },
    prevPart() {
      this.part -= 1;
    },
    addClassActive(data) {
      if (data) {
        jQuery(".list-item").not(".selected").removeClass("active");
        jQuery("#" + data).addClass("active");
      }
    },
    removeClassActive(data) {
      if (data) {
        jQuery(".list-item").not(".selected").removeClass("active");
      }
    },
    resetCheckedVideo() {
      this.selectedSeriesId = "avg";
      this.shownReportIds = ["avg"];
      this.seriesData = [formatSeriesDataSummaryAvgStep1(this.summaryAvg)];
      this.videos.forEach((video) => {
        video.checked = false;
      });
      EventBus.$emit("resetVideos");
      EventBus.$emit("resetVideosMarket");
    },
    videosMaxLength() {
      let videoArrayNum = this.videos.length;
      let videoLengthArray = [];
      for (let i = 0; i < videoArrayNum; i++) {
        videoLengthArray.push(this.videos[i].video_length);
      }
      this.videosMaxLengthSec = videoLengthArray.reduce(
        (a, b) => Math.max(a, b),
        0
      );
    },
    defaultStepOption() {
      let sec = this.videosMaxLengthSec;
      if (0 <= sec && sec <= 8) {
        this.step = 1;
      } else if (9 <= sec && sec <= 16) {
        this.step = 2;
      } else if (17 <= sec && sec <= 32) {
        this.step = 4;
      } else if (33 <= sec && sec <= 64) {
        this.step = 8;
      } else if (65 <= sec) {
        this.step = 15;
      }
    },
  },
  computed: {
    nextPartEnabled: function () {
      let seriesLength = this.seriesData.map((e) => e.data.length);
      let max = Math.max(...seriesLength) - 1;
      return max > this.part * this.step * 8;
    },
    prevPartEnabled: function () {
      return this.part > 1;
    },
    interactionButtonColor: function () {
      let color_rank_2 =
        this.project &&
        this.project.interaction_btn_1 &&
        Object.keys(this.project.interaction_btn_1).length
          ? this.project.interaction_btn_1.color
          : "";
      let color_rank_3 =
        this.project &&
        this.project.interaction_btn_2 &&
        Object.keys(this.project.interaction_btn_2).length
          ? this.project.interaction_btn_2.color
          : "";
      return {
        rank_2: color_rank_2,
        rank_3: color_rank_3,
      };
    },
  },
  mounted() {
    this.videosMaxLength();
    this.defaultStepOption();
  },
  created() {
    EventBus.$on("changeStep", this.handleChangeStepChart);
    EventBus.$on("resetCheckedVideo", this.resetCheckedVideo);
    EventBus.$on("selectedChart", this.selectedChart);
  },
  beforeDestroy() {
    EventBus.$off("changeStep", this.handleChangeStepChart);
    EventBus.$off("resetCheckedVideo", this.resetCheckedVideo);
  },
};
</script>
<style lang="scss" scoped>
</style>
