<template>
  <div>
    <div v-if="!videos.length && isProject">
      <div class="card-download-manual mt-5">
        <img class="img-logo" src="images/svg/logo_download_manual.svg"/>
        <div class="title-large mt-2">Welcome to PlayAds</div>
        <div class="title-small mt-1">動画コンテンツの反応値を測定して<br>安全かつ効果的に配信しよう！</div>
        <div
          :style="disabled ? styleButton : {}"
          class="btn btn-download-manual btn-primary-outline mt-4"
          @click="downloadManual()"
        >
          マニュアルダウンロード
        </div>
      </div>
    </div>
    <div v-if="videos.length && isProject">
      <template v-for="video in videos">
        <div
          class="card card-video card-subtitle mb-4"
          v-if="video.image_url"
          :key="'cart-video-' + video.id"
        >
          <div class="content" style="padding-bottom: 18px;">
            <div class="title mb-3">
              <div>{{ video.name }}</div>
            </div>
            <div class="body">
              <div class="body__total flex-wrap">
                <img class="card-image" :src="video.image_url + '2.jpg'"/>
                <div class="item">
                  <div class="description">Interaction</div>
                  <div class="body__total-number">
                    <i :class="'icon icon-reaction icon-interaction mr-2'"></i>
                    <div class="number">{{ totalInteraction(video) | decimalOnePoint }}%</div>
                  </div>

                </div>
              </div>
              <div class="body-statist flex-wrap">
                <div class="statist-interaction mr-4">
                  <img
                      :src="video[`interaction_btn_1`].btn_icon_url"
                      class="icon icon-reaction mr-2"
                      alt=""
                  >
                  <div class="number">{{ eachInteraction(video, 1) | decimalOnePoint }}%</div>
                </div>
                <div class="statist-interaction mr-4">
                  <img
                      :src="video[`interaction_btn_2`].btn_icon_url"
                      class="icon icon-reaction mr-2"
                      alt=""
                  >
                  <div class="number">{{ eachInteraction(video, 2) | decimalOnePoint }}%</div>
                </div>
                <div class="statist-interaction">
                  <img
                      :src="video[`interaction_btn_3`].btn_icon_url"
                      class="icon icon-reaction mr-2"
                      alt=""
                  >
                  <div class="number">{{ eachInteraction(video, 3) | decimalOnePoint }}%</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-card" @click="gotoDetailVideo(video)">
            <i class="icon icon-arrow-right"></i>
          </div>
        </div>
      </template>
    </div>
    <div v-if="!isProject">
      <div class="card-download-manual mt-5">
        <img class="img-logo" src="images/svg/logo_download_manual.svg"/>
        <div class="title-large mt-2">Welcome to PlayAds</div>
        <div class="title-small mt-1">動画コンテンツの反応値を測定して<br>安全かつ効果的に配信しよう！</div>
        <div
          :style="disabled ? styleButton : {}"
          class="btn btn-download-manual btn-primary-outline mt-4"
          @click="downloadManual()"
        >
          マニュアルダウンロード
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/commons/event_bus";

export default {
  props: {
    videos: Array,
    fromRouter: String,
    isProject: Boolean,
    dataReport: Array,
  },
  filters: {
    decimalOnePoint: function (numberString) {
      return Number(numberString).toFixed(1)
    }
  },
  data() {
    return {
      currentUser: {},
      disabled: false,
      styleButton: {
        opacity: 0.75,
        cursor: 'default'
      }
    };
  },
  methods: {
    downloadManual() {
      if (!this.disabled) {
        this.disabled = true;
        let url = this.$router.resolve({name: "api.download.manual"}).href;
        let params = {
          user_id: this.currentUser.user_id
        }
        this.$http2.get(url, params, (data) => {
            let url = data.download_url;
            let label = url.substr(url.lastIndexOf("/") + 1);
            this.$http2.downloadPDF(url, label, () => {
                this.disabled = false
              },
              () => {
                this.disabled = false
              });
          },
          () => {
            this.disabled = false
          }
        );
      }
    },
    totalInteraction(video) {
      if (!video) return 0;
      const videoData = this.dataReport.find(item => item.video_id === video.id)
      return Math.round(Number(videoData.general_interaction_total) * 10) / 10
    },

    eachInteraction(video, number) {
      if (!video) return 0;
      const videoData = this.dataReport.find(item => item.video_id === video.id)
      return Math.round(Number(videoData[`${number}_interaction_total`]) * 10) / 10
    },

    resetCheckedVideo() {
      EventBus.$emit('resetCheckedVideo');
    },
    gotoDetailVideo(video) {
      let video_id = video.id || video.video_id;
      let top_detail_url = this.$router.resolve({
        name: 'top-detail-step-1',
        params: {
          video_id,
        },
      });
      localStorage.setItem("from_router", this.$props.fromRouter);
      window.open(top_detail_url.href, '_blank');
    }
  },
  mounted() {
    this.currentUser = this.$store.getters.currentUser;
  }
};
</script>
<style lang="scss" scoped>
.card-download-manual {
  height: 347px;
  box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #49ab94;

  .title-large {
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
  }

  .title-small {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  }

  .btn-download-manual {
    padding: 10px 0;
    width: 90%;
    display: flex;
    justify-content: center;
  }

  .item {
    margin-left: unset !important;
  }
}

.icon-reaction {
  width: 24px;
  height: 24px;
}
</style>
