<template>
  <div class="comment-ai mt-10">
    <div class="font-bold title-section">コメントディスカバリーAI</div>
    <div class="order">
      <div class="text" @click="changeOrder">{{ order === ORDER_COMMENT.INTERACTION.value ? '反応値順' : '動画順' }}</div>
      <i :class="'icon icon-reaction icon-table-arrow-bottom ml-2'"></i>
    </div>
    <CommentCard
        v-for="(item, key) in comments"
        v-show="!isLoadingComment"
        @changeOrder="changeOrder"
        :order="order"
        :commentsCard="item.comments"
        :key="item.type"
        :type="Number(key)"
        :videoDetail="videoDetail"
    >
    </CommentCard>
    <div v-show="isLoadingComment" class="flex position-absolute icon-loading-map">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import CommentCard from "@/components/Top/Step-1/CommentCard.vue";
import {ORDER_COMMENT} from "@/commons/constants";

export default {
  components: {
    CommentCard
  },
  props: {
    comments: Object,
    order: {type: Number, default: ORDER_COMMENT.INTERACTION.value},
    isLoadingComment: Boolean,
    videoDetail: Object,
  },
  data() {
    return {
      ORDER_COMMENT,
    }
  },
  methods: {
    changeOrder() {
      this.$emit("changeOrder")
    }
  },

}
</script>

<style lang="scss" scoped>
@import "@/styles/_variable.scss";
@import "@/styles/_button.scss";
.comment-ai {
  .title-section {
    @include font(xxl);
    margin-bottom: 10px;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    color: #333333;
  }

  .button-download {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
      padding: 10px;
      height: 40px;
      width: 308px;
    }

    margin-bottom: 39px;
  }

  .order {
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #333;
    font-family: "Avenir Next";
    align-items: center;
    justify-content: flex-end;

    .text {
      cursor: pointer;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
    }
  }

  .icon-loading-map {
    width: 100%;
    height: 100%;
    z-index: 20;

    .loader {
      border: 2px solid #49ab94;
      border-radius: 50%;
      border-top: 2px solid #ffffff;
      width: 36px;
      height: 36px;
      -webkit-animation: spin 1s linear infinite; /* Safari */
      animation: spin 1s linear infinite;
      margin: auto;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

</style>